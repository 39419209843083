import React, { useEffect } from "react"
import { gsap } from 'gsap'




const AnimatedLogo = (props) => {


  useEffect(()=>{
    gsap.from(".coin", {
      duration: 1,
      opacity: 0,
      y: 50,
      ease: "back" 
    });
    
    gsap.from(".text", {
      duration: 0.5,
      opacity: 0,
      x: 50,
      // scale: 0.3,
      ease: "back",
      stagger: 0.1,
      delay: 1,
    });
    
    var coin = document.querySelector('.coin-group');
    coin.addEventListener('mouseover', onMouseOver);
    coin.addEventListener('mouseleave', onMouseLeave);
    
    function onMouseOver() {
      gsap.to(".coin", {
        duration: 0.7,
        y: -9,
        fill: '#f2a900',
      })
    }
    
    function onMouseLeave() {
      gsap.to(".coin", {
        duration: 1,
        ease: "bounce",
        y: 0,
        z: 0,
        // fill: "none",
      })
      gsap.to(".coin2",{
        duration:1,
        fill: "white",
      })
      gsap.to(".coin3",{
        duration:1,
        fill: "white",
      })
    }
    return function cleanupListener() {
      window.removeEventListener('mouseover', onMouseOver)
      window.removeEventListener('mouseleave', onMouseLeave)   
    }

  },[])




  return (
    <svg
      className='logo-svg'
      width={200}
      height="100%"
      // length="auto"
      viewBox="0 0 228.574 35.111"
      
      {...props}
    >
      <defs>
        <style>
          {
            "\n      .cls-1 {\n        fill: #231f20;\n      }\n\n      .cls-2 {\n        fill: #fff;\n      }\n    "
          }
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path className="cls-1 text" d="M47.206,1.684h3.4V33.462h-3.4Z" />
          <path
            className="cls-1 text"
            d="M62.9,32.537a10.766,10.766,0,0,1-4.171-4.085,11.82,11.82,0,0,1-1.548-6.085,11.906,11.906,0,0,1,1.548-6.127A10.773,10.773,0,0,1,62.9,12.155a11.691,11.691,0,0,1,5.719-1.44,11.573,11.573,0,0,1,5.7,1.44,10.672,10.672,0,0,1,4.128,4.085,12.044,12.044,0,0,1,1.526,6.127,11.957,11.957,0,0,1-1.526,6.085,10.665,10.665,0,0,1-4.128,4.085,11.573,11.573,0,0,1-5.7,1.44A11.691,11.691,0,0,1,62.9,32.537Zm9.632-2.709a7.71,7.71,0,0,0,2.881-2.988,9.147,9.147,0,0,0,1.075-4.516,9.3,9.3,0,0,0-1.053-4.515,7.416,7.416,0,0,0-2.86-2.967,8.064,8.064,0,0,0-7.933.022,7.665,7.665,0,0,0-2.9,2.988,9.154,9.154,0,0,0-1.075,4.515,9.068,9.068,0,0,0,1.075,4.473,7.658,7.658,0,0,0,2.9,2.988,7.817,7.817,0,0,0,3.977,1.053A7.624,7.624,0,0,0,72.532,29.828Z"
          />
          <path
            className="cls-1 text"
            d="M90.183,32.494a10.07,10.07,0,0,1-3.913-4.021,12.577,12.577,0,0,1-1.44-6.149,11.77,11.77,0,0,1,1.462-5.869,10.632,10.632,0,0,1,4.042-4.085,11.434,11.434,0,0,1,5.8-1.484,11.577,11.577,0,0,1,4.429.8,10.311,10.311,0,0,1,3.483,2.386l-1.935,2.494a7.954,7.954,0,0,0-2.645-1.913,8.107,8.107,0,0,0-3.246-.624A7.757,7.757,0,0,0,92.183,15.1a7.643,7.643,0,0,0-2.838,2.967,8.835,8.835,0,0,0-1.032,4.3,9.3,9.3,0,0,0,1.032,4.515,7.162,7.162,0,0,0,2.773,2.881,7.57,7.57,0,0,0,3.806.99,8.419,8.419,0,0,0,3.354-.646A9.649,9.649,0,0,0,102.245,28l1.935,2.364a11.2,11.2,0,0,1-3.763,2.666,11.937,11.937,0,0,1-4.665.86A11.125,11.125,0,0,1,90.183,32.494Z"
          />
          <path
            className="cls-1 text"
            d="M123.874,33.462q-.043-.387-.194-1.462t-.236-2.022a7.481,7.481,0,0,1-3.032,2.945,9.3,9.3,0,0,1-4.407,1.011,10,10,0,0,1-4.107-.795,6.288,6.288,0,0,1-2.773-2.3,6.426,6.426,0,0,1-.989-3.613,6.259,6.259,0,0,1,1.139-3.741,7.2,7.2,0,0,1,3.29-2.45,14.022,14.022,0,0,1,5.2-.861,22.15,22.15,0,0,1,5.547.688V19.4a5.376,5.376,0,0,0-1.57-4.106,6.035,6.035,0,0,0-4.321-1.484,9.8,9.8,0,0,0-6.493,2.237l-1.462-2.666a13.214,13.214,0,0,1,8.084-2.581,10.792,10.792,0,0,1,4.837,1.011,7.175,7.175,0,0,1,3.139,2.945,9.278,9.278,0,0,1,1.1,4.644v7.568a44.612,44.612,0,0,0,.473,6.494Zm-3.741-3.205A5.728,5.728,0,0,0,122.476,28a6.8,6.8,0,0,0,.839-3.441V23.485a18,18,0,0,0-2.451-.516,20.713,20.713,0,0,0-2.8-.171,8.464,8.464,0,0,0-4.9,1.161,3.693,3.693,0,0,0-1.634,3.181,3.422,3.422,0,0,0,1.376,2.838,5.846,5.846,0,0,0,3.7,1.075A7.424,7.424,0,0,0,120.133,30.257Z"
          />
          <path className="cls-1 text" d="M134.366,1.684h3.4V33.462h-3.4Z" />
          <path
            className="cls-1 text"
            d="M154.107,9.955a12.051,12.051,0,0,1,10.418,5.419l-5.8,3.192a5.364,5.364,0,0,0-4.621-2.436c-3.318,0-5.545,2.436-5.545,5.838s2.227,5.839,5.545,5.839a5.367,5.367,0,0,0,4.621-2.436l5.8,3.192a12.054,12.054,0,0,1-10.418,5.42,11.79,11.79,0,0,1-12.266-12.015A11.79,11.79,0,0,1,154.107,9.955Z"
          />
          <path
            className="cls-1 text"
            d="M178.341,9.955a11.789,11.789,0,0,1,12.266,12.013,12.269,12.269,0,0,1-24.532,0A11.79,11.79,0,0,1,178.341,9.955Zm0,6.175c-3.318,0-5.545,2.436-5.545,5.838s2.227,5.839,5.545,5.839,5.545-2.436,5.545-5.839S181.66,16.13,178.341,16.13Z"
          />
          <path
            className="cls-1 text"
            d="M197.617.335a4.006,4.006,0,0,1,4.2,3.948,4.209,4.209,0,0,1-8.4,0A4.005,4.005,0,0,1,197.617.335Zm3.529,10.208V33.394h-7.057V10.543Z"
          />
          <path
            className="cls-1 text"
            d="M228.574,33.394h-7.057V20.877c0-3.194-1.512-4.831-4.158-4.831-2.815,0-4.832,2.058-4.832,5.628v11.72H205.47V10.543h7.057v2.814a7.5,7.5,0,0,1,6.6-3.4c5.881,0,9.452,3.906,9.452,10.417Z"
          />
          <g className="coin-group">
            <circle
              className="cls-1 coin coin1"
              cx={17.556}
              cy={17.556}
              r={17.556}
            />
            <path
              className="cls-2 coin coin2"
              d="M12.834,25.263h-3.5V7.948h3.5Z"
            />
            <path
              className="cls-2 coin coin3"
              d="M21.55,12.5a6.518,6.518,0,0,1,5.611,2.9l-2.874,1.609a3.163,3.163,0,0,0-2.737-1.425,3.09,3.09,0,0,0-2.391.989,3.4,3.4,0,0,0-.92,2.46,3.5,3.5,0,0,0,.92,2.483,3.091,3.091,0,0,0,2.391.99,3.163,3.163,0,0,0,2.737-1.426l2.874,1.586a6.537,6.537,0,0,1-5.611,2.921,6.418,6.418,0,0,1-6.645-6.554A6.411,6.411,0,0,1,21.55,12.5Z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default AnimatedLogo;
